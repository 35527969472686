import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Select, InputLabel, FormControl } from '@mui/material';


function RefundModalComponent({ row, refetch }) {

    const [refundType, setRefundType] = useState('');
    const { register, formState: { errors } } = useForm();
    const axiosPrivate = useAxiosPrivate();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        for (const item of formData) {
            const [key, value] = item
            const makeData = {
                ref: row.order_tracking_id,
                idempotency_key: row.uuid,
                payment_id: row.qp_payment_id,
                amount: refundType === 'full' ? row.total : Number(value),
            }
            mutate(makeData)
        }
    };
    const postData = async (data) => {
        const response = axiosPrivate.post('/refund/mobilepay/payment', data);
        return response;
    }


    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            refetch()

            if (successData?.data?.success == false) {
                alert('Sorry, we could not proceed with refund as the captured amount today is less than the amount you want to refund. Wait and refund, when total captured amount is higher')
            }
        }
    })
    const handleRefundTypeChange = (event) => {
        const selectedValue = event.target.value;
        setRefundType(selectedValue);
    };

    return (
        <>
            <div>
                <>
                    {row.shopify_order_id && <a
                        href={`https://admin.shopify.com/store/slikekspressen/orders/${row.shopify_order_id}`}
                        target="_blank"
                        rel="noreferrer"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title="Go to shopify"
                        className="btn btn-success bg-shopify-color mx-2 text-white fs-6"
                    >
                        <i className="fab fa-shopify"></i>
                    </a>}

                    {((row.payment_method === 'mobilepay' && row.order_tracking_link && row.refund_amount === null) || (row.payment_method === 'mobilepay' && row.payment_status === 'captured' && row.refund_amount === null)) &&
                        <button
                            title="Refund"
                            onClick={handleOpen}
                            className="btn btn-yellow mx-2  fs-6"
                        >
                            <i class="fa-solid fa-money-bill-transfer"></i>
                        </button>}

                    <Dialog open={open} onClose={handleClose} aria-labelledby={`refundModalLabel-${row.order_id}`} maxWidth="sm" fullWidth>
                        <DialogTitle id={`refundModalLabel-${row.order_id}`}>Refund</DialogTitle>
                        <form onSubmit={(e) => onSubmit(e)}>
                            <DialogContent dividers>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Select Refund Type</InputLabel>
                                    <Select
                                        value={refundType}
                                        onChange={handleRefundTypeChange}
                                        label="Select Refund Type"
                                        required
                                    >
                                        <MenuItem value="" disabled>Select Refund Type</MenuItem>
                                        <MenuItem value="full">Full Amount</MenuItem>
                                        <MenuItem value="partial">Partial Amount</MenuItem>
                                    </Select>
                                </FormControl>

                                {refundType === 'partial' && (
                                    <TextField
                                        label="Partial Amount"
                                        type="number"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        {...register('amount', {
                                            required: 'This field is required',
                                            pattern: {
                                                value: /^[0-9]+(\.[0-9]+)?$/,
                                                message: "Please enter a valid number",
                                            },
                                            max: { value: row.total, message: `Cannot exceed ${row.total}` },
                                            min: { value: 1, message: "Must be at least 1" },
                                        })}
                                        inputProps={{ min: 1, max: row.total, step: 0.01 }}
                                        error={!!errors.amount}
                                        helperText={errors.amount ? errors.amount.message : "Enter Partial Amount"}
                                    />
                                )}

                                {refundType === 'full' && (
                                    <input type="hidden" {...register('amount', { value: row.total })} />
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">Close</Button>
                                <Button type="submit" color="primary" disabled={!refundType}>Save changes</Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </>
            </div >
        </>
    )
}
const RefundModal = React.memo(RefundModalComponent)
export default RefundModal
