import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import Navbar from '../../../components/Navbar';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import Loaders from '../../../components/Loaders';
import { Switch } from '@mui/material';
import Swal from 'sweetalert2';

export default function GiftCardImage() {
    const axiosPrivate = useAxiosPrivate();
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success ',
            cancelButton: 'btn btn-danger me-3'
        },
        buttonsStyling: false
    });

    const handleGiftCardImage = async () => {
        const response = await axiosPrivate.get(`gift-image/all?country_code=dk`);
        return response.data.data; // Adjust based on your API response structure
    };

    const { data: allGiftCards = [], isLoading, refetch } = useQuery({
        queryKey: ['get-gift-cards'],
        queryFn: handleGiftCardImage,
        cacheTime: 5000,
    });

    const [data, setData] = useState([]);

    // Update data when allGiftCards changes
    useEffect(() => {
        if (allGiftCards.length > 0) {
            setData(allGiftCards);
        }
    }, [allGiftCards]);

    const handleGiftCardImageStatus = useCallback(async (id, value) => {
        await axiosPrivate.put(`gift-image/is_active/${id}`, { is_active: value });
        refetch();
    }, [axiosPrivate, refetch]);

    const handleMessageCardDelete = useCallback(async (id) => {
        const result = await swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        });

        if (result.isConfirmed) {
            try {
                const res = await axiosPrivate.delete(`gift-image/delete/${id}`);
                if (res.status === 200) {
                    swalWithBootstrapButtons.fire('Deleted!', 'Gift card has been deleted.', 'success');
                    refetch();
                }
            } catch (error) {
                console.error('Error deleting the card:', error);
                swalWithBootstrapButtons.fire('Error!', 'There was a problem deleting the card.', 'error');
            }
        }
    }, [axiosPrivate, refetch, swalWithBootstrapButtons]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => <img src={row.image} alt="Images" height='50px' />,
            header: "Image",
        },
        {
            accessorFn: (row) => row.title,
            header: "Name",
        },
        {
            accessorFn: (row) => row.price,
            header: "Price",
        },
        {
            accessorFn: (row) => row.count,
            header: "Total Uses",
        },
        {
            accessorFn: (row) => (
                <Switch checked={row.is_active} onChange={(e) => handleGiftCardImageStatus(row.id, e.target.checked)} />
            ),
            header: "Status",
        },
        {
            accessorFn: (row) => (
                <button onClick={() => handleMessageCardDelete(row.id)} className='btn btn-danger mx-2'>
                    <i className="fa-solid fa-trash"></i>
                </button>
            ),
            id: "action",
            header: "Action",
        }
    ], [handleGiftCardImageStatus, handleMessageCardDelete]);

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowOrdering: true,
        enableSorting: false,
        muiRowDragHandleProps: ({ table }) => ({
            onDragEnd: async () => {
                const { draggingRow, hoveredRow } = table.getState();

                if (hoveredRow && draggingRow) {
                    const newData = [...data];
                    const draggingRowIndex = draggingRow.index;
                    const hoveredRowIndex = hoveredRow.index;

                    const draggedRow = newData.splice(draggingRowIndex, 1)[0];
                    newData.splice(hoveredRowIndex, 0, draggedRow);
                    setData(newData);

                    const sortList = newData.map((item, index) => ({
                        id: item.id,
                        sort_order: index + 1,
                    }));

                    try {
                        await axiosPrivate.put(`gift-image/sort-alignment`, { sort_list: sortList });
                    } catch (error) {
                        console.error('Error updating sort order:', error);
                    }
                }
            },
        }),
    });

    return (
        <>
            <Navbar
                title="Gift Card"
                btnTitle="Add New Gift Card"
                btnLink="/admin/dashboard/gift-cards/create"
            />
            {isLoading && (
                <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            )}
            <div className='container my-5'>
                {data.length > 0 && (
                    <MaterialReactTable table={table} />
                )}
            </div>
        </>
    );
}
